import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MarqueeStats = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api-mainnet.magiceden.io/v2/ord/btc/stat?collectionSymbol=runestone'));
        const data = JSON.parse(response.data.contents);
        setStats(data);
      } catch (error) {
        console.error('Error fetching collection stats:', error);
      }
    };

    fetchStats();
  }, []);

  // Function to format BTC amounts correctly to two decimal places
  const formatBTC = (sats) => {
    const btcValue = parseInt(sats, 10) / 100000000;
    return btcValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div>
      {stats ? (
        <div className='marquee'>
        <span>
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()}
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()} 
        ᛤ RUNESONE 
        ᛤ Floor Price: {formatBTC(stats.floorPrice)} BTC 
        ᛤ Total Listed: {stats.totalListed.toLocaleString()} 
        ᛤ Pending Transactions: {stats.pendingTransactions.toLocaleString()}
        </span>
        </div>
      ) : (
        <p>Loading stats...</p>
      )}
    </div>
  );
};

export default MarqueeStats;
