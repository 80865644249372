import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LatestFloorPrice = () => {
  const [floorPriceBTC, setFloorPriceBTC] = useState(null);
  const [btcToUsdRate, setBtcToUsdRate] = useState(null);
  const [selectedConversion, setSelectedConversion] = useState('Apple Vision Pros'); // Corrected to singular

  // REAL WORLD ASSET PRICED IN USD
  const assetPricesUSD = {
    appleVisionPro: 3499,
    cyberTruck: 81895,
    macBookPro: 2499,
    costcoDog: 1.5,
    mcdonaldsCheeseburger: 2.89,
    nissanAltima: 1819,
    gramWeed: 20,
    usDollar: 1,
  };

  const fetchData = async () => {
    try {
      // Fetch collection stats including the floor price
      const response = await axios.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api-mainnet.magiceden.io/v2/ord/btc/stat?collectionSymbol=runestone'));
      const data = JSON.parse(response.data.contents);
      setFloorPriceBTC(data.floorPrice / 100000000); // Convert from satoshis to BTC

      // Fetch the current BTC to USD exchange rate
      const btcRateResponse = await axios.get('https://blockchain.info/ticker');
      setBtcToUsdRate(btcRateResponse.data.USD.last);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const conversionDisplay = () => {
    if (!floorPriceBTC || !btcToUsdRate) return 'Loading...';

    const runestoneValueInUSD = floorPriceBTC * btcToUsdRate;
    let result;
    switch (selectedConversion) {
      case 'Apple Vision Pros':
        result = runestoneValueInUSD / assetPricesUSD.appleVisionPro;
        break;
      case 'M3 MacBook Pros':
        result = runestoneValueInUSD / assetPricesUSD.macBookPro;
        break;
      case 'Cybertrucks':
        result = runestoneValueInUSD / assetPricesUSD.cyberTruck;
        break;
      case 'Costco Dogs':
        result = runestoneValueInUSD / assetPricesUSD.costcoDog;
        break;
      case '1996 Nissan Altimas':
        result = runestoneValueInUSD / assetPricesUSD.nissanAltima;
        break;
      case 'Grams of Weed':
        result = runestoneValueInUSD / assetPricesUSD.gramWeed;
        break;
      case 'USD':
        result = runestoneValueInUSD / assetPricesUSD.usDollar;
        break;
      case 'McDonalds 🍔':
        result = runestoneValueInUSD / assetPricesUSD.mcdonaldsCheeseburger;
        break;
      case 'BTC':
        return `${floorPriceBTC.toFixed(8)} BTC`;
      default:
        return `${result.toFixed(2)} ${selectedConversion}`;
    }
    return `${result.toFixed(2)} ${selectedConversion}`;
  };

  return (
    <div>
      <div className='spacer'></div>
      <div className='spacer'></div>
      <img src="https://metal-midi.grahamdowney.com/pic/skullight.gif" alt="skull gif"></img>
      <h1>1 RUNESTONE = {conversionDisplay()}</h1>
      <div className='live'>
        <img width="69px" src="https://media4.giphy.com/media/mWnDeIKilkwDcrM2VT/giphy.gif" alt="loading gif"></img>
      </div>
      <select value={selectedConversion} onChange={(e) => setSelectedConversion(e.target.value)}>
        <option value="Apple Vision Pros">Apple Vision Pro</option>
        <option value="Grams of Weed">Grams of Weed</option>
        <option value="M3 MacBook Pros">16-inch M3 MacBook Pro</option>
        <option value="Cybertrucks">Cybertruck</option>
        <option value="Costco Dogs">Costco Dog</option>
        <option value="1996 Nissan Altimas">1996 Nissan Altima</option>
        <option value="McDonalds 🍔">McDonalds Cheeseburger</option>
        <option value="BTC">BTC</option>
        <option value="USD">USD</option>
      </select>
      <br/>
      <div className='spacer'></div>
      <img src="https://metal-midi.grahamdowney.com/pic/thornbar.gif" alt="thornbar gif"></img>
    </div>
  );
};

export default LatestFloorPrice;
