import React from 'react';
import { Canvas } from '@react-three/fiber';
import Chart from './component/chart'; // Adjust according to your project structure
import LatestFloorPrice from './component/floor';
import BackgroundScene from './component/rock';
import CollectionStats from './component/stats';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import MarqueeStats from './component/marquee';

import './App.css';

const App = () => {
  return (
    <div className="App">
      {/* Position the Canvas as a fixed background */}
      <div className="background-scene">
          <BackgroundScene />
      </div>
      <div className="app-content">
        <header className="App-header">
          <LatestFloorPrice />
          <div className='spacer'></div>
          <div className='spacer'></div>

          <h1>ᛤ</h1>
          <div className='spacer'></div>
          <div className='spacer'></div>
          <img src="https://geocities.restorativland.org/Area51/Cavern/2595/bttle.gif" alt="Battle Gif" />
          <h2>
            <img src="https://geocities.restorativland.org/Area51/Cavern/2595/torch1.gif" alt="Torch" />
            WELCOME TO RUNEDASH
            <img src="https://geocities.restorativland.org/Area51/Cavern/2595/torch1.gif" alt="Torch" />
          </h2>
          <h6><a target="_blank" href="https://x.com/ordinalos">@ordinalOS</a> x <a target="_blank" href="https://x.com/MEonBTC">@MEonBTC</a></h6>
          <div>
            <CollectionStats />
          </div>
          <div className='spacer'></div>
          <div className='spacer'></div>
        </header>
        <Chart />
        <div className='coffee'>
        <h6>buy me a goat: <a href="https://mempool.space/address/bc1p3df7t0zlrv2t6l4y70g8m9q0hwmqruccdzswmavyg22egmf39xfqv8qmur">bc1...cdzswmavyg22egmf39xfqv8qmur</a></h6>
        </div>
        <div className="footer">
          <a href="https://x.com/ordinalos"><img src="https://geocities.restorativland.org/Area51/Cavern/4429/Email2.gif" alt="Email Icon" /></a>
        </div>
      </div>
      <div className='buynow'>
        <button className="big-button" onClick={() => window.open("https://magiceden.io/ordinals/marketplace/runestone", "_blank")}>RUNESTONE</button> LISTINGS ON MAGICEDEN
      </div>
      <div className='spacer'></div>
      <div className='spacer'></div>
      <div className='spacer'></div>
      <div className='spacer'></div>
      <SpeedInsights/>  
      <MarqueeStats />
      <Analytics/>
    </div>
    
  );
};

export default App;
