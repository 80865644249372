import React, { Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Environment, useGLTF } from '@react-three/drei';

function Model() {
    const gltf = useGLTF('/runestone.glb', true);
    useFrame((state, delta) => {
      gltf.scene.rotation.y += delta * 0.2; // Adjust rotation speed here
    });
    // Adjust these values to visually center the model's rotation
    gltf.scene.position.x = -.45; // Adjust X position if needed
    gltf.scene.position.y = -3; // Adjust Y position to lift or lower the model
    gltf.scene.position.z = -4; // Adjust Z position if needed
  
    return <primitive object={gltf.scene} dispose={null} />;
  }

  export default function BackgroundScene() {
    return (
      <Suspense fallback={null}>
        <Canvas
          camera={{ position: [-1, 1, 6], fov: 12 }} // Adjust the position for zoom level and field of view
        >
          <ambientLight intensity={0.1} />
          <directionalLight position={[-10, 10, 5]} intensity={1} />
          <Model />
          <OrbitControls />
          <Environment preset="sunset" background={false} />
        </Canvas>
      </Suspense>
    );
  }