import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CollectionStats = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api-mainnet.magiceden.io/v2/ord/btc/stat?collectionSymbol=runestone'));
        const data = JSON.parse(response.data.contents);
        setStats(data);
      } catch (error) {
        console.error('Error fetching collection stats:', error);
      }
    };

    fetchStats();
  }, []);

  // Function to format BTC amounts correctly to two decimal places
  const formatBTC = (sats) => {
    const btcValue = parseInt(sats, 10) / 100000000;
    return btcValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div>
      {stats ? (
        <div>
          <p>Total Volume: {formatBTC(stats.totalVolume)} BTC</p>
          <p>Owners: {stats.owners.toLocaleString()}</p>
          <p>Supply: {stats.supply.toLocaleString()}</p>
          <p>Floor Price: {formatBTC(stats.floorPrice)} BTC</p>
          <p>Total Listed: {stats.totalListed.toLocaleString()}</p>
          <p>Pending Transactions: {stats.pendingTransactions.toLocaleString()}</p>
          {/* <p>Inscription Range: {stats.inscriptionNumberMin.toLocaleString()} - {stats.inscriptionNumberMax.toLocaleString()}</p> */}
          <p>Symbol: {stats.symbol}</p>
          <img width="200px" src="https://metal-midi.grahamdowney.com/pic/bcross.gif" alt="loading gif"></img>
        </div>
      ) : (
        <p>Loading stats...</p>
      )}
    </div>
  );
};

export default CollectionStats;
