import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Chart = () => {
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const yAxisMargin = 0.001; // Margin value for minY and maxY

  const fetchData = async () => {
    try {
      const endTime = new Date().getTime(); // Current time in milliseconds since Unix epoch
      const startTime = endTime - (24 * 60 * 60 * 1000); // Start time is 24 hours (1 day) before endTime

      const response = await axios.get(
        `https://api.allorigins.win/get?url=${encodeURIComponent(
          `https://api-mainnet.magiceden.io/v2/ord/btc/timeseries?symbol=runestone&timeframe=1h&startTime=${startTime}&endTime=${endTime}`
        )}`
      );

      const responseData = JSON.parse(response.data.contents);
      const processedData = responseData.map(point => ({
        ...point,
        floorPrice: point.floorPrice / 100000000,
        time: new Date(point.time).toLocaleString()
      }));

      setData(processedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch initial data

    // Set interval to fetch updated data every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(() => {
      fetchData(); // Fetch updated data
    }, 300000); // Interval set to 5 minutes (300,000 milliseconds)

    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);

    // Cleanup function to clear interval and remove event listener
    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts or rerenders
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  const minY = data.length > 0 ? Math.min(...data.map(point => point.floorPrice)) - yAxisMargin : 0;
  const maxY = data.length > 0 ? Math.max(...data.map(point => point.floorPrice)) + yAxisMargin : 1;

  return (
    <ResponsiveContainer width="100%" height={isMobile ? 300 : 900}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 20,
        }}
        style={{ background: 'rgba(255, 255, 255, 0.01)', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)' }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
        <XAxis 
          dataKey="time" 
          stroke="rgba(255, 255, 255, 0.75)" 
          tick={{ fontSize: isMobile ? 10 : 14 }} 
        />
        <YAxis 
          stroke="rgba(255, 255, 255, 0.75)" 
          tick={{ fontSize: isMobile ? 10 : 14 }}
          domain={[minY, maxY]} // Set custom Y-axis domain
        />
        <Tooltip
          contentStyle={{
            backgroundColor: 'black',
            borderColor: 'white',
            borderRadius: '4px'
          }}
          itemStyle={{ color: 'white' }}
          labelStyle={{ color: 'orange' }}
        />
        <Legend wrapperStyle={{ fontSize: isMobile ? 10 : 14 }} />
        <Line
          type="monotone"
          dataKey="floorPrice"
          stroke="#FFA500"
          dot={false}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
